<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('agencies')"
                        :is-filter="false"
                >
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('agencies')"
                              :is-filter="false"
                >
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="datatable.queryParams.filter.name"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.code"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
                         v-if="checkPermission('classroom_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Components
// Others
import qs from 'qs'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/en/vuecal.js'
import ShowForm from "@/modules/agencies/pages/ShowForm.vue";
import AgenciesServices from "@/services/AgenciesServices";

export default {
    components: {
        ShowForm,
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('agencies')
        }
    },
    data() {
        return {
            formId: null,
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        hidden: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'classroom_show',
                                callback: (row) => {
                                    this.showForm(row.code);
                                }
                            },
                        ]
                    },

                    {
                        label: this.toUpperCase('code'),
                        field: ('code'),
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: ('name'),
                        hidden: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'code',
                    page: 1,
                    limit: 20
                }
            }

        }
    },

    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return AgenciesServices.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(code) {
            this.formId = code
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
    }
}
</script>


