<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                </b-form-group>
                <b-form-group :label="$t('code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                    </b-input-group>
                </b-form-group>
                <template v-if="form.master_commission.length > 0">
                    <b-form-group :label="$t('master_commission')" />
                    <b-table striped hover bordered :items="form.master_commission" class="mb-3"></b-table>
                </template>
                <template v-if="form.dual_master_commission.length > 0">
                    <b-form-group :label="$t('dual_master_commission')"/>
                    <b-table striped hover bordered :items="form.dual_master_commission" class="mb-3"></b-table>
                </template>
                <template v-if="form.bachelor_commission.length > 0">
                    <b-form-group :label="$t('bachelor_commission')"/>
                    <b-table striped hover bordered :items="form.bachelor_commission" class="mb-3"></b-table>
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>
//Service

import AgenciesServices from "@/services/AgenciesServices";

export default {
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            form:{
                code: null,
                name: null,
                bachelor_commission:[],
                master_commission:[],
                dual_master_commission:[]
            }
        }
    },
    async created() {
        this.get(this.formId)
    },
    methods: {
        get(id) {
            AgenciesServices.show(id)
                .then((response) => {
                    this.form = response.data.data

                    this.formLoading = false;
                })
                .catch((error) => {
                    this.showErrors(error)
                });
        }
    }
}
</script>
